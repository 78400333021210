@import '../../../../scss/theme-bootstrap';

.site-footer {
  clear: both;
  text-transform: uppercase;
  line-height: 25px;
  background: $color-footer-background;
  color: $white;
  @include breakpoint($xlarge-up) {
    margin: 0 auto;
  }
  a {
    color: $white;
  }
  &__header {
    display: block;
    font-weight: bold;
    margin-bottom: 7px;
    cursor: default;
    font-size: 18px;
    text-transform: uppercase;
    &:hover {
      color: inherit;
      text-decoration: none;
    }
  }
  &__wrapper-minimal,
  &__wrapper {
    padding: 20px 13px;
    position: relative;
    @include breakpoint($footer-breakpoint) {
      padding: 50px 20px 30px;
    }
    @include breakpoint($xlarge-up) {
      padding-#{$rdirection}: 50px;
    }
    &.site-footer__wrapper--primary {
      @include breakpoint($footer-breakpoint) {
        display: flex;
        display: -webkit-flex;
      }
      @include breakpoint($xlarge-up) {
        max-width: $max-width-large;
        margin: 0 auto;
      }
    }
  }
  &__logos {
    text-transform: none;
    @include breakpoint($footer-breakpoint) {
      position: static;
      order: 0;
      -webkit-order: 0;
      width: 25%;
      &.site-footer__logos--has-certification-blocks {
        width: 29%;
      }
    }
    a {
      display: block;
    }
    img {
      display: block;
      height: auto;
    }
  }
  &__trustmark {
    padding-bottom: 25px;
    @include breakpoint($footer-breakpoint) {
      padding-bottom: 50px;
    }
    img {
      max-width: 50%;
      margin: 0 auto;
      @include breakpoint($footer-breakpoint) {
        margin-bottom: 0;
        max-width: 180px;
        max-height: 134px;
        width: auto;
      }
      .site-footer__logos--has-certification-blocks & {
        margin-bottom: 15px;
        margin-#{$ldirection}: 15px;
        @include breakpoint($footer-breakpoint) {
          margin-bottom: 0;
        }
      }
    }
  }
  &__certification {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    .basic-tout {
      &__image {
        @include swap-direction(margin, 0 20px 0 0);
        img {
          max-width: none;
          width: revert-layer;
        }
      }
      &__text {
        text-align: inherit;
        padding-#{$ldirection}: 0;
        padding-top: 0;
      }
    }
  }
  &__customer,
  &__professional,
  &__connect {
    @include breakpoint($footer-breakpoint) {
      width: 25%;
    }
    span.menu__link--lvl-1 {
      cursor: default;
    }
    .menu__link--lvl-1 {
      display: block;
      font-weight: bold;
      margin-bottom: 7px;
      font-size: 18px;
      text-transform: uppercase;
      &:hover {
        color: inherit;
        text-decoration: none;
      }
    }
  }
  &__customer {
    margin-bottom: 20px;
    @include breakpoint($footer-breakpoint) {
      order: 1;
      -webkit-order: 1;
      .site-footer__logos--has-certification-blocks ~ & {
        width: 21%;
      }
    }
    .menu__item--lvl-2 {
      @if $is_custom_footer {
        text-align: center;
        border: 1px solid $color-gray;
        margin-bottom: 15px;
        font-size: 16px;
        line-height: get-line-height(16px, 40px);
        height: 40px;
        font-weight: bold;
        letter-spacing: 0.05em;
      }
      @include breakpoint($footer-breakpoint) {
        margin-bottom: 0;
        width: auto;
        line-height: get-line-height(14px, 25px);
        font-weight: 300;
        border: none;
        text-align: $ldirection;
        font-size: 14px;
        letter-spacing: 0;
        height: auto;
      }
      @include breakpoint($mobile-only) {
        @if $is_custom_footer {
          border-color: $white;
          background: $white;
        }
      }
      a {
        @include breakpoint($mobile-only) {
          @if $is_custom_footer {
            color: $color-off-black;
          }
        }
      }
    }
  }
  &__connect {
    margin-bottom: 20px;
    @include breakpoint($footer-breakpoint) {
      order: 2;
      -webkit-order: 2;
    }
    .menu__item--lvl-1,
    .menu__item--lvl-2 {
      display: inline-block;
      font-size: 24px;
      margin-#{$rdirection}: 30px;
      @include breakpoint($footer-breakpoint) {
        margin-#{$rdirection}: 25px;
        line-height: 1.5;
      }
      @include breakpoint($mobile-only) {
        margin-#{$rdirection}: 15px;
      }
      &:last-child,
      &:only-child {
        margin-#{$rdirection}: 0;
      }
      a:hover {
        text-decoration: none;
      }
      .social {
        display: none;
      }
      .icon {
        width: 24px;
        fill: $white;
        max-height: 16px;
        vertical-align: middle;
        @include breakpoint($footer-breakpoint) {
          max-height: none;
        }
      }
      .snsicon {
        cursor: pointer;
        transition-duration: 0.3s;
        &:hover {
          opacity: 0.6;
        }
      }
    }
  }
  &__signup {
    margin-bottom: 20px;
    text-transform: none;
    .site-email-signup {
      input[type='submit'] {
        &.site-email-signup__submit {
          background: $white;
          color: $color-off-black;
          font-weight: 700;
          @include breakpoint($footer-breakpoint) {
            width: auto;
            margin: 0;
          }
          &:hover {
            background: $color-off-black;
            color: $white;
            border-color: $white;
          }
        }
      }
      input[type='email'].site-email-signup__field,
      input[type='tel'].site-email-signup__field,
      input[type='text'].site-email-signup__field {
        color: $white;
        border: 1px solid $white;
        background-color: $color-off-black;
      }
    }
  }
  &__professional {
    @include breakpoint($footer-breakpoint) {
      order: 0;
      -webkit-order: 0;
    }
    .menu__link--lvl-2,
    .menu__item--lvl-2 {
      font-size: 14px;
      font-weight: 300;
    }
  }
  &__primary {
    background: $color-footer-background;
    color: $white;
    border-top: 1px solid $white;
    border-bottom: 1px solid $white;
    .salon-takeover & {
      display: none;
    }
  }
  &__secondary-minimal,
  &__secondary {
    background: $color-footer-background;
    color: $white;
    font-weight: 300;
    padding-bottom: 42px;
    @include breakpoint($xlarge-up) {
      max-width: $max-width-large;
      margin: 0 auto;
    }
    .menu__list {
      @include breakpoint($mobile-only) {
        display: flex;
        justify-content: flex-start;
        flex-flow: wrap;
        column-gap: 15px;
      }
    }
    .menu--lvl-1 {
      font-size: 0;
      .last {
        img {
          vertical-align: middle;
        }
      }
    }
    .site-footer__wrapper-minimal,
    .site-footer__wrapper {
      padding: 10px 13px;
      background: $color-footer-background;
      @include breakpoint($footer-breakpoint) {
        padding: 10px 20px 2px;
      }
    }
    .menu__item--lvl-1 {
      display: inline-block;
      padding: 5px 0;
      font-size: 14px;
      @include breakpoint($footer-breakpoint) {
        width: auto;
        margin: 0 10px;
        padding: 0;
      }
    }
  }
  &__regional {
    display: table;
    width: 100%;
    @include breakpoint($footer-breakpoint) {
      display: block;
      float: $rdirection;
      width: auto;
      margin-#{$ldirection}: 0;
      position: relative;
      z-index: 2;
      margin-top: -2px;
    }
  }
  &__country,
  &__language {
    display: table-cell;
    width: 50%;
    @include breakpoint($portrait-up) {
      width: auto;
      display: inline-block;
    }
  }
  &__country {
    margin-top: -1px;
    &-image {
      width: 24px;
      height: auto;
      vertical-align: middle;
      margin-top: -8px;
      @include breakpoint($portrait-up) {
        margin-top: -11px;
      }
    }
    .select-box {
      z-index: 2;
      @include breakpoint($portrait-up) {
        margin-#{$ldirection}: 7px;
      }
      @include breakpoint($mobile-only) {
        width: 100%;
      }
      &__options {
        width: auto;
      }
    }
    .select-box__label {
      background: none;
      border: 0;
      &:after,
      &.open:after {
        color: $white;
        background-color: $white;
      }
    }
    .select-box__options {
      top: auto;
      bottom: 40px;
      color: $color-off-black;
      @include breakpoint($mobile-only) {
        width: 100%;
      }
    }
    > select {
      width: 100%;
      color: $white;
      background-color: $color-off-black;
      border: 1px solid $white;
    }
  }
  &__language {
    font-size: 14px;
    @include breakpoint($portrait-up) {
      vertical-align: middle;
      width: auto;
      margin-top: -6px;
      margin-#{$rdirection}: 10px;
    }
    &-select__link {
      color: $color-gray;
      &--selected {
        color: $color-off-black;
      }
    }
  }
  a:active,
  a:hover {
    text-decoration: underline;
    outline: 0;
  }
  .footer_content {
    display: none;
  }
}

#lpChat {
  .lp_actions_bar_container {
    .lp_actions_bar {
      .lp_action_item {
        text-transform: capitalize;
      }
    }
  }
  &.lp_main_ltr * {
    min-width: auto !important;
  }
  .lp_header_text.lp_text a {
    color: #6d6e70 !important;
    text-decoration: none !important;
  }
  .lp_main {
    .lp_main_area {
      .lp_location_bottom {
        .lp_actions_button {
          text-align: $ldirection !important;
        }
      }
    }
  }
  .lp_send_button {
    text-align: $rdirection !important;
  }
}

#liveperson_floating_chat_button {
  .section-avhair-quiz & {
    display: none;
    @include breakpoint($portrait-up) {
      display: block;
    }
  }
}

@include breakpoint($footer-breakpoint) {
  .footer {
    .site-footer {
      &__wrapper-minimal {
        padding: 42px 20px 4px;
      }
    }
  }
}
